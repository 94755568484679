<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Name</th>
          <th class="text-center">Short Name</th>
          <th class="text-center">Number Of Days</th>
          <th class="text-center">Speciality</th>
          <th class="text-center">Is Carriable</th>
          <th class="text-center">Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in tableItems" :key="i">
          <td>{{ item.name }}</td>
          <td class="text-center">{{ item.short_name }}</td>
          <td class="text-center">{{ item.number_of_days }}</td>
          <td class="text-center">{{ item.speciality }}</td>
          <td class="text-center">{{ item.is_carriable }}</td>
          <td class="text-center">{{ item.status }}</td>
          <td>
            <button class="btn btn-primary btn-sm"
                    @click="goToEdit(item)"
                    style="cursor: pointer"
            >
              <i class="fas fa-eye"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="tableItems.length < 0" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

const props  = defineProps({
  tableItems     : Object,
});
const route  = useRoute();
const router = useRouter();

const goToEdit = (item) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId
  }

  let query = route.query;
  query.leavePolicyId = item.id;

  router.push({
    name:  'leave-policy-add',
    params: param,
    query: query
  })
}
</script>
